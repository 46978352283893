<template>
  <div class="hotelList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>酒店列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <div class="box">
        <span>酒店名称:</span>
        <el-input placeholder="请输入酒店名称" v-model.trim="list.hotelName"></el-input>
        <span>酒店标签:</span>
        <el-input placeholder="请输入酒店标签名称" v-model.trim="list.label"></el-input>
        <span>星级:</span>
        <el-select
          clearable
          @clear="onClear('start')"
          v-model="list.startType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in startType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <span>上下架:</span>
        <el-select
          clearable
          @clear="onClear('shangxia')"
          v-model="list.status"
          placeholder="请选择"
        >
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="box">
        <span>所在城市:</span>
        <el-input placeholder="请输入城市名称" v-model.trim="list.city"></el-input>
        <span>所属分类:</span>
        <el-select
          clearable
          @clear="onClear('cate')"
          v-model="cateIdValue"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
        <el-select
          class="second"
          v-show="cateIdValue"
          clearable
          @clear="onClear('cate')"
          v-model="list.cateId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options5"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="onSearch">查找</el-button>
        <el-button
          type="primary"
          @click="$router.push('/addhotel')"
          v-if="$store.state.powerList.indexOf('hotel:hotel:list:insert') !== -1"
          >添加</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      @selection-change="onSelectChange"
    >
      <el-table-column align="center" type="selection" width="55"></el-table-column>
      <el-table-column align="center" prop="detp" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            ref="saveTagInput"
            size="small"
            v-if="row.inputVisible"
            v-model="row.dept"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <!-- v-else -->
          <el-button
            class="button-new-tag"
            size="small"
            v-else
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" prop="hotelId" label="酒店ID"> </el-table-column>
      <el-table-column align="center" prop="hotelImg" label="展示图">
        <template slot-scope="{ row }">
          <el-image
            style="width: 80px; height: 80px"
            :src="row.hotelImg"
            :preview-src-list="[row.hotelImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="hotelName" label="酒店名称">
      </el-table-column>
      <el-table-column align="center" prop="cateName" label="所属分类"> </el-table-column>
      <el-table-column align="center" prop="label" label="标签"> </el-table-column>
      <el-table-column align="center" prop="linkman" label="联系人"> </el-table-column>
      <el-table-column align="center" prop="hotelTel" label="客服电话"> </el-table-column>
      <el-table-column align="center" prop="strStatus" label="酒店状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="390">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            v-if="
              row.strStatus === '上架' &&
              $store.state.powerList.indexOf('hotel:hotel:list:up') !== -1
            "
            @click="onTheShelf(row, 2)"
            >下架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-if="
              row.strStatus === '下架' &&
              $store.state.powerList.indexOf('hotel:hotel:list:down') !== -1
            "
            @click="onTheShelf(row, 1)"
            >上架</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onTheShelf(row, 0)"
            v-if="$store.state.powerList.indexOf('hotel:hotel:list:delete') !== -1"
            >删除</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onEdit(row)"
            v-if="$store.state.powerList.indexOf('hotel:hotel:list:update') !== -1"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="$router.push(`/hotelJournal/${row.hotelId}`)"
            v-if="$store.state.powerList.indexOf('hotel:hotel:list:log') !== -1"
            >查看日志</el-button
          >
          <el-button size="mini" type="primary" @click="onShowAddress(row.hotelId)"
            >查看页面路径</el-button
          >
          <!-- <el-button size="mini" type="primary" @click="creatCode(row.hotelId)"
            >生成二维码</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <div class="btn">
      <el-button
        type="primary"
        @click="onAllChangeStatus(1)"
        v-if="$store.state.powerList.indexOf('hotel:hotel:list:up') !== -1"
        >批量上架</el-button
      >
      <el-button
        type="primary"
        @click="onAllChangeStatus(2)"
        v-if="$store.state.powerList.indexOf('hotel:hotel:list:down') !== -1"
        >批量下架</el-button
      >

      <el-button
        type="primary"
        @click="onAllChangeStatus(0)"
        v-if="$store.state.powerList.indexOf('hotel:hotel:list:delete') !== -1"
        >批量删除</el-button
      >
    </div>
    <el-dialog title="查看酒店页面地址" :visible.sync="showAddress" width="35%">
      <div id="address">
        酒店页面地址:<el-input v-model="hotelAddress" readonly id="wrapper"></el-input>
        <el-button type="primary" icon="el-icon-document-copy" @click="copyinviteUrl"
          >一键复制</el-button
        >
      </div>
      <div slot="footer">
        <el-button @click="showAddress = false">取 消</el-button>
        <el-button type="primary" @click="showAddress = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input
          style="width: 300px"
          v-model.trim="QRcodeSize"
          placeholder="请输入生成的二维码大小"
        ></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  hotelList,
  addHotel,
  editSort,
  cahngeStatus,
  getHotelDetails,
} from "../../api/hotelList";
import { hotelCateList, subClassification, } from "../../api/hotel";
export default {
  name: "hotelList",
  data() {
    return {
      codeVisible: false,
      hotelId: "",
      QRcodeSize: "",
      imgUrl: "",
      showAddress: false,
      cateIdValue: null,
      options5: [],
      tableData: [],
      pagination: {},
      options: [],
      options1: [],
      selectionOn: [],
      cateValue: "",
      hotelAddress: "",
      startType: [
        {
          value: 5,
          label: "五星级",
        },
        {
          value: 4,
          label: "四星级",
        },
        {
          value: 3,
          label: "三星级",
        },
      ],
      status: [
        {
          value: 1,
          label: "上架",
        },
        {
          value: 2,
          label: "下架",
        },
      ],
      list: {
        currentPage: 1,
        pageSize: 5,
        hotelName: "",
        cateId: null,
        label: "",
        startType: null,
        status: null,
        city: "",
      },
    };
  },

  created() {
    this.cateIdValue = this.$store.state.hotelcateValue;
    this.list.cateId = this.$store.state.hotelcateId;

    this.getHotelList();
    this.getHotelCateList();
  },
  watch: {
    cateIdValue: async function () {
      const { data } = await subClassification({
        fatherId: this.cateIdValue,
      });
      this.$store.commit("setHotelcateValue", this.cateIdValue);
      this.options5 = data.data;
    },
    "list.cateId": function () {
      this.$store.commit("setHotelcateId", this.list.cateId);
    },
  },
  methods: {
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await createEr({
          hotelId: this.hotelId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },
    creatCode(id) {
      this.hotelId = id;
      this.codeVisible = true;
      this.imgUrl = "";
    },
    onShowAddress(id) {
      this.hotelAddress = "hotel/pages/hotelDetail/hotelDetail?hotelId=" + id;
      this.showAddress = true;
    },
    copyinviteUrl() {
      this.$nextTick(() => {
        var range = document.createRange();
        range.selectNode(document.getElementById("wrapper"));
        var selection = window.getSelection();
        if (selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    async getHotelList() {
      const { data } = await hotelList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      console.log(data, "酒店裂变");
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async getHotelCateList() {
      const { data } = await subClassification({
        fatherId: 0,
      });
      this.options = data.data;
    },
    async handleInputConfirm(row) {
      console.log(row);
      const { data } = await editSort({
        cateId: row.id,
        dept: row.dept,
      });
      row.inputVisible = false;
      this.getHotelList();
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    onClear(status) {
      if (status === "cate") {
        this.list.cateId = null;
      } else if (status == "start") {
        this.list.startType = null;
      } else if (status == "shangxia") {
        this.list.status = null;
      }
    },
    async handleInputConfirm(row) {
      const { data } = await editSort({
        hotelId: row.hotelId,
        dept: row.dept,
      });
      row.inputVisible = false;
    },
    onTheShelf(row, status) {
      this.$confirm("是否改变这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await cahngeStatus({
            status: status,
            ids: [row.hotelId].join(","),
          });
          if (data.code == 0) {
            this.getHotelList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    onSearch() {
      this.getHotelList();
    },
    onEdit(row) {
      console.log(row);
      this.$router.push(`/edithotel/${row.hotelId}`);
    },
    onSelectChange(selection, row) {
      var newArr = [];
      selection.map((item) => {
        newArr.push(item.hotelId);
      });
      this.selectionOn = newArr;
    },
    async onAllChangeStatus(status) {
      this.$confirm("是否批量改变这些数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.selectionOn.length == 0) {
            return this.$message({
              type: "error",
              message: "请选择要删除的数据!",
            });
          }
          const { data } = await cahngeStatus({
            status,
            ids: this.selectionOn.join(","),
          });
          if (data.code == 0) {
            this.getHotelList();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleSizeChange(size) {
      console.log(size);
      this.list.pageSize = size;
      this.getHotelList();
    },
    handleCurrentChange(current) {
      console.log(current);
      this.list.currentPage = current;
      this.getHotelList();
    },
  },
};
</script>
<style lang="less" scoped>
.hotelList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    height: 60px;
    .box {
      // display: flex;
      // justify-content: space-around;
      // align-items: center;
      width: 8000px;
      span {
        margin: 0 20px 0 40px;
      }
      .el-button {
        margin-left: 40px;
      }
      .second {
        margin-left: 20px;
      }
      .el-select,
      .el-input {
        width: 200px;
      }
    }
  }
  /deep/.el-table {
    margin-top: 100px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .btn {
    margin-top: 50px;
    float: left;
  }
  #address {
    display: flex;
    align-items: center;

    .el-input {
      width: 350px;
    }
  }
}
</style>
